import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING
} from "./types";

axios.defaults.baseURL = 'https://api.workoutleague.co/v1';

// Register User
export const registerUser = (userData, history) => dispatch => {
    axios
        .post("/api/users/register", userData)
        .then(res => history.push("/login")) // re-direct to login on successful register
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Login - get user token
export const loginUser = userData => dispatch => {

    dispatch(setUserLoading());

    axios
        .post("/auth", userData)
        .then(res => {
            // Save to localStorage
            // Set token to localStorage
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            axios.defaults.headers.common['Authorization'] = token;
            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);
            //console.log(decoded);
            // Set current user
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => {

            /*if (err.response) {
                // Request made and server responded
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The request was made but no response was received
                console.log(err.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', err.message);
            }*/

            dispatch({
                type: GET_ERRORS,
                payload: {
                    hasError: true,
                    httpStatus: err.response ? err.response.status : null,
                    message: err.response ? err.response.data.message : err
                }
            });

        });
};

export const resetError = abc => dispatch => {
    dispatch({
        type: GET_ERRORS,
        payload: {
            hasError: false,
        }
    });
};

// Set logged in user
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};
// User loading
export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};
// Log user out
export const logoutUser = () => dispatch => {
    // Remove token from local storage
    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to empty object {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
};