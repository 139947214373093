import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, resetError } from "../../actions/authActions";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Notification from "../layout/Notification";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: "54px"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#1976d2",
        height: "72px",
        width: "72px",
        fontSize: "large",
        fontWeight: "1000"
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    whiteIcon: {
        color: "#fff"
    }
}));

const Login = (props) => {

    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(props.auth.loading);
    }, [props.auth.loading])

    const emailChanged = event => {
        setEmail(event.target.value);
        if (props.errors.hasError)
            props.resetError();
    };
    const passwordChanged = event => {
        setPassword(event.target.value);
        if (props.errors.hasError)
            props.resetError();
    };

    const onSubmit = e => {

        e.preventDefault();

        props.resetError();

        const userData = {
            email: email,
            password: password
        };
        props.loginUser(userData);
    };

    let redirect = null;
    if (props.auth.isAuthenticated) {
        redirect = <Redirect to="/add" />;
    }

    let buttonContent = loading ? <CircularProgress className={classes.whiteIcon} /> : <span>Sign in</span>

    return (
        <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={10}>
                {redirect}
                {/*<div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Welcome to WOL
                    </Typography>
                </div>*/}
                <Notification open={props.errors.hasError} message={props.errors.message} />
                <form className={classes.form} onSubmit={onSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        error={props.errors.hasError}
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={emailChanged}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        error={props.errors.hasError}
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={passwordChanged}
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >{buttonContent}</Button>
                </form>
            </Grid>
        </Grid>
    );
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser, resetError }
)(Login);