import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IOSSwitch from './IosSwitch';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(14),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const Settings = (props) => {

    let timer = null;
    const [runkeeperActive, setRunkeeperActive] = useState(false);
    const [runkeeperAvailable, setRunkeeperAvailable] = useState(false);

    const classes = useStyles();
    const [expanded, setExpanded] = useState('panel1');

    const handlePanel = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleRunkeeper = () => {
        setRunkeeperActive(!runkeeperActive);
        if (!runkeeperActive)
            window.location.href = `https://api.workoutleague.co/v1/integrations/runkeeper/connect?userId=${props.auth.user.id.replace("user-", "")}`;
    };

    useEffect(() => {
        const fetchData = async () => {

            try {
                const runkeeperActivity = await axios(`/integrations/runkeeper/activity?userId=${props.auth.user.id.replace("user-", "")}`);
                if (runkeeperActivity.data.connected) {
                    setRunkeeperActive(true);
                }

                setRunkeeperAvailable(true);
            }
            catch (error) {
                alert('Failed to load RK');
            }
        };
        fetchData();
        return () => clearTimeout(timer);
    }, [timer, props.auth.user.id]);

    return (
        <div>
            <Grid
                container
                spacing={0}
                alignItems="flex-start"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={10}>
                    <div className={classes.root}>
                        <h3>Settings</h3>
                        <Accordion expanded={expanded === 'panel1'} onChange={handlePanel('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className={classes.heading}>Integrations</Typography>
                                <Typography className={classes.secondaryHeading}></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControlLabel
                                    control={<IOSSwitch disabled={!runkeeperAvailable} checked={runkeeperActive} onChange={handleRunkeeper} name="runkeeper" />}
                                    label={!runkeeperAvailable ? "Loading status..." : (runkeeperActive ? "Runkeeper enabled" : "Runkeeper disabled")}
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handlePanel('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography className={classes.heading}>Account</Typography>
                                <Typography className={classes.secondaryHeading}></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Change password and stuff?
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

Settings.propTypes = {
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps,
    {}
)(Settings);