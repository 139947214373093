import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Redirect } from "react-router-dom";
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    bar: {
        height: "64px"
    }
}));

const Navbar = (props) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [redirect, setRedirect] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSettings = () => {
        //window.location.href = "/#/settings";
        setRedirect(<Redirect to="/settings" />);
        handleClose();
    };

    const handleAddworkout = () => {
        //window.location.href = "/#/add";
        setRedirect(<Redirect to="/add" />);
        handleClose();
    };

    const handleSignout = () => {
        props.logoutUser();
        handleClose();
    }

    let userButton;
    if (props.auth.isAuthenticated) {

        userButton = <div>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleAddworkout}>Add workout</MenuItem>
                <MenuItem onClick={handleSettings}>Settings</MenuItem>
                <MenuItem onClick={handleSignout}>Sign out</MenuItem>
            </Menu>
        </div>;
    }
    else {
        userButton = null;
    }

    return (
        <div className={classes.root}>
            {redirect}
            <AppBar position="fixed">
                <Toolbar className={classes.bar}>
                    {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                </IconButton>*/}
                    <Typography variant="h6" className={classes.title}>
                        <div className="wolWrapper">
                            WOL <span role="img" aria-label="Arm">&#128170;</span>
                        </div>
                    </Typography>
                    {userButton}
                </Toolbar>
            </AppBar>
        </div>
    )
};


/*function Navbar() {
    const classes = useStyles();
    //const [auth, setAuth] = React.useState(true);
    const auth = React.useState()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const auth = this.state.auth.isAuthenticated;

    return (
        
    );
}*/

Navbar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps, { logoutUser })(Navbar);

/*import React, { Component } from "react";
import { Link } from "react-router-dom";
class Navbar extends Component {
    render() {
        return (
            <div className="navbar-fixed">
                <nav className="z-depth-0">
                    <div className="nav-wrapper black">
                        <Link
                            to="/"
                            className="col s5 brand-logo center white-text"
                        >
                            <div>
                                <div style={{ float: 'left' }}>
                                    <b>WOL</b>
                                </div>
                                <i className="material-icons">directions_run</i>
                            </div>
                        </Link>
                    </div>
                </nav>
            </div>
        );
    }
}
export default Navbar;*/