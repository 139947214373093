import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckIcon from '@material-ui/icons/Check';
import {
    CircularProgress, makeStyles, TextField, MenuItem, Grid, Button,
    DialogTitle, Dialog, DialogContentText, DialogContent, DialogActions
} from '@material-ui/core';
import axios from "axios";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(6),
    },
    runkeeper: {
        backgroundColor: "#1596D2",
        margin: theme.spacing(2, 0, 0),
        height: "54px",
        color: "#fff"
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        height: "54px"
    },
    formControl: {
        //margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginBottom: theme.spacing(-0.5)
    },
    uploadButton: {
        marginTop: theme.spacing(2),
        height: "54px"
    },
    whiteIcon: {
        color: "#fff"
    },
    wrapper: {
        //margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: "#fff",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    uploaded: {
        //color: green[500]
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

/*
var console = (function (oldCons) {
    return {
        log: function (text) {
            alert(text);
            oldCons.log(text);
            // Your code
        },
        info: function (text) {
            oldCons.info(text);
            // Your code
        },
        warn: function (text) {
            alert('warn_:' + text);
            oldCons.warn(text);
            // Your code
        },
        error: function (text) {
            alert('err__:' + text);
            oldCons.error(text);
            // Your code
        }
    };
}(window.console));

//Then redefine the old console
window.console = console;
*/

const AddWorkout = (props) => {

    let timer = null;
    const [types, setTypes] = useState([]);
    const [pendingRunkeeper, setPendingRunkeeper] = useState({ lastActivity: {} });
    const [loadingRunkeeper, setLoadingRunkeeper] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploads, setUploads] = useState([]);
    const [uploaded, setUploaded] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const classes = useStyles();

    const [inputs, setInputs] = useState({
        workoutType: '',
        duration: '',
        comment: '',
        url: ''
    });

    const [dialogTexts, setDialogTexts] = useState({
        title: '',
        text: '',
        button: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            const workoutTypes = await axios("/workout-types");
            setTypes(workoutTypes.data);
            try {
                const runkeeperActivity = await axios(`/integrations/runkeeper/activity?userId=${props.auth.user.id.replace("user-", "")}`);
                if (!runkeeperActivity.data.alreadySeen && runkeeperActivity.data.lastActivity) {
                    const running = workoutTypes.data.filter(i => i.name === "Löpning")[0];
                    const pending = {
                        lastActivity: {
                            ...runkeeperActivity.data.lastActivity,
                            workoutTypeId: runkeeperActivity.data.lastActivity.type === "Running" ? running.typeId : ""
                        }
                    };
                    setPendingRunkeeper(pending);
                }
            }
            catch (error) {
                alert('Failed to load RK');
            }
        };
        fetchData();
        return () => clearTimeout(timer);
    }, [timer, props.auth.user.id]);

    const handleSubmit = async (e) => {
        setErrors({});
        setLoading(true);
        e.preventDefault();
        //console.log(inputs);
        //console.log(uploads);
        if (!inputs.duration || !inputs.comment || !inputs.workoutType) {
            setErrors({
                duration: !inputs.duration,
                comment: !inputs.comment,
                workoutType: !inputs.workoutType
            });
            setLoading(false);
        }
        else {
            try {
                //console.log('sent request for user ' + props.auth.user.id);
                const result = await axios.post("/add-workout", {
                    userId: props.auth.user.id,
                    imageIds: uploads,
                    duration: parseInt(inputs.duration),
                    typeId: parseInt(inputs.workoutType),
                    comment: inputs.comment,
                    url: inputs.url
                });
                const uplodData = await result.data;
                //console.log('returned');
                //console.log(uplodData.created);
                if (uplodData.created) {
                    setDialogTexts({
                        title: (<div>Great workout! <span role="img" aria-label="strong">&#128170;</span></div>),
                        text: 'Your workout is saved!',
                        button: 'OK'
                    });
                    setShowDialog(true);
                    clearForm();
                }
                else {
                    setDialogTexts({
                        title: (<div>Oh no! <span role="img" aria-label="strong">&#128549;</span></div>),
                        text: 'Your workout is not saved! Something went wrong',
                        button: 'OK'
                    });
                    setShowDialog(true);
                }
            }
            catch (err) {
                console.log(err.response);
                console.log(err.toJSON());
                setDialogTexts({
                    title: (<div>Oh no! <span role="img" aria-label="strong">&#128549;</span></div>),
                    text: 'Your workout is not saved! Something went wrong: ' + JSON.stringify(err),
                    button: 'OK'
                });
                setShowDialog(true);
            }
        }

        setLoading(false);
    }

    const clearForm = () => {
        setInputs({
            workoutType: '',
            duration: '',
            comment: '',
            url: ''
        });
        setUploads([]);
    }

    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
    }

    const fileChange = async event => {
        setUploading(true);
        const file = event.target.files[0];
        event.target.value = null;
        console.log(file);

        try {
            const result = await axios.post("/upload-image", { userId: props.auth.user.id });
            const upload = await result.data;
            if (upload.putUrl) {
                console.log(upload.putUrl);
                await axios.put(upload.putUrl, file, {
                    headers: {
                        "Content-Type": file.type
                    },
                    transformRequest: [(data, headers) => {
                        delete headers.common.Authorization;
                        return data;
                    }]
                });

                setUploading(false);
                setUploads([...uploads, upload.filePart]);
                setUploaded(true);
            }

            timer = setTimeout(() => { setUploaded(false); }, 2500);
        }
        catch (err) {
            console.log(err.response);
            console.log(err.toJSON());
            setUploading(false);
        }
    }

    const uploadIcon = uploaded ? <CheckIcon className={classes.uploaded} /> : <CloudUploadIcon />;
    const uploadText = uploaded ? "Uploaded" : "Add picture";

    let submitButton = loading ? <CircularProgress className={classes.whiteIcon} /> : <span>Save</span>

    let fileUpload = React.createRef();

    const uploadButtonClick = () => {

        fileUpload.current.click();
    }

    let runkeeperButton = loadingRunkeeper ? <CircularProgress className={classes.whiteIcon} /> : <span>Load {pendingRunkeeper.lastActivity.duration} min. {pendingRunkeeper.lastActivity.type} from RK</span>;

    const runkeeperClick = () => {
        setLoadingRunkeeper(true);

        const images = pendingRunkeeper.lastActivity.images || [];
        if (images.length > 0) {
            setUploads([...uploads, ...images.map(i => i.filePart)]);
        }

        setInputs({
            workoutType: pendingRunkeeper.lastActivity.workoutTypeId,
            duration: pendingRunkeeper.lastActivity.duration,
            comment: `Workout loaded from Runkeeper:
Distance (km): ${pendingRunkeeper.lastActivity.distance}
Avg pace: ${pendingRunkeeper.lastActivity.avgPace}
Climb (m): ${pendingRunkeeper.lastActivity.climb}
Calories: ${pendingRunkeeper.lastActivity.calories}
Avg heart rate: ${pendingRunkeeper.lastActivity.avgHeartRate || "N/A"}
Pictures: ${images.length}`,
            url: pendingRunkeeper.lastActivity.url
        });

        setLoadingRunkeeper(false);
        setPendingRunkeeper({ lastActivity: {} });
    };

    const RunkeeperButton = () => (
        <Button
            type="button"
            onClick={runkeeperClick}
            fullWidth
            variant="contained"
            disabled={loading}
            className={classes.runkeeper}
        >{runkeeperButton}</Button>
    );

    return (
        <div>
            <Dialog onClose={handleCloseDialog} open={showDialog}>
                <DialogTitle>{dialogTexts.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogTexts.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        {dialogTexts.button}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={10}>
                    <form className={classes.form} noValidate encType="multipart/form-data" method="post" onSubmit={handleSubmit}>
                        {pendingRunkeeper.lastActivity.duration ? <RunkeeperButton /> : null}
                        <TextField
                            id="workoutType"
                            name="workoutType"
                            error={errors.workoutType}
                            select
                            fullWidth
                            required
                            variant="outlined"
                            label="Workout type"
                            className={classes.textField}
                            onChange={handleInputChange}
                            value={inputs.workoutType}
                            margin="normal"
                        >
                            {types.map(option => (
                                <MenuItem key={option.typeId} value={option.typeId}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id="duration"
                            name="duration"
                            type="number"
                            error={errors.duration}
                            className={classes.textField}
                            label="Time (in minutes)"
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            onChange={handleInputChange}
                            value={inputs.duration}
                        />
                        <TextField
                            id="comment"
                            name="comment"
                            label="Comment"
                            multiline
                            rows="5"
                            error={errors.comment}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            onChange={handleInputChange}
                            value={inputs.comment}
                        />
                        <TextField
                            id="url"
                            name="url"
                            type="text"
                            value={inputs.url}
                            label="URL (optional)"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={handleInputChange}
                        />
                        <div className={classes.wrapper}>
                            <Button
                                onClick={uploadButtonClick}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.uploadButton}
                                startIcon={uploadIcon}
                                disabled={uploading}
                            >{uploadText}</Button>
                            {uploading && <CircularProgress className={classes.buttonProgress} />}
                            <input type="file" id="fileUpload" ref={fileUpload} accept="image/*" onChange={fileChange} />
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            className={classes.submit}
                        >{submitButton}</Button>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
}

AddWorkout.propTypes = {
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps,
    {}
)(AddWorkout);