import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Login from "./components/auth/Login";
import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import PrivateRoute from "./components/private-route/PrivateRoute";
import AddWorkout from "./components/addworkout/AddWorkout";
import Settings from "./components/settings/Settings";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

validateLogin();

function App() {

  return (
    <Provider store={store}>
      <Router>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Navbar />
          <Route exact path="/" component={Login} />
          <Switch>
            <PrivateRoute exact path="/add" component={AddWorkout} />
            <PrivateRoute exact path="/settings" component={Settings} />
          </Switch>
        </Container>
      </Router>
    </Provider>
  );
}

function validateLogin() {
  // Check for token to keep user logged in
  if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));
    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser());
      // Redirect to login
      window.location.href = "./";
    }
  }
}

export default App;
